/* PasswordInput.css */
.input-group {
    border: 1px solid #ced4da;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .input-group-text {
    background-color: #f8f9fa;
    border: none;
    border-left: 1px solid #ced4da;
  }
  
  .input-group-text:hover {
    background-color: #e9ecef;
  }
  
  .input-group-text:focus {
    background-color: #e9ecef;
  }
  
  .input-group-text svg {
    color: #6c757d;
    font-size: 1.25rem;
  }
  
  .input-group-text:hover svg {
    color: #495057;
  }
  
  .input-group-text:focus svg {
    color: #495057;
  }
  
  .input {
    border: none;
    border-radius: 0;
    outline: none;
    box-shadow: none;
  }
  