.password-criteria {
 
  padding-left: 0;
  margin-top: 2px;
}

.password-criteria li {
  color: red;
}

.password-criteria li.valid {
  color: green;
}

.input-group-text {
  cursor: pointer;
}

.btn-show-password {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.card-login {
  width: 100%;
  max-width: 400px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.label-12 {
  font-size: 12px;
 
}

.text-center {
  text-align: center;
}





